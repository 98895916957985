import React from 'react';
import logo from './logo.svg';
import coming from './coming.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={coming} className="App-logo" alt="logo" />
      </header>
    </div>
  );
}

export default App;
